<template>
<div class="section section-contact-us text-center">
    <div class="container">
        <div v-if="teams==null">
            <h2 class="title">Vent venligst!</h2>
            <p class="description">Vi indlæser alle vores hold....</p>
        </div>
        <div v-else-if="teams.length==0">
            <h2 class="title">Vi har desværre ingen hold i øjeblikket.</h2>
            <p class="description">Følg med på vores sociale medier for at vide hvornår der er flere hold!</p>
        </div>
        <div class="teams-grid" v-else>
            <div class="team card" v-for="team in teams" :key="team.id">
                <router-link style="text-decoration: none; color: inherit;" :to="'/hold/'+team.type">
                    <div class="card-body top">
                        <h2>{{team.name}}</h2>
                    </div>
                    <div class="card-body">
                        <p>

                            {{timeSpan(team.beginning,team.ending)}}
                        </p>
                        <p style="margin-top:0;">{{team.text}}</p>
                        <div class="timeline">
                            <div>
                                <span>{{formatDate(team.beginning)}}</span>
                                <span>Begynder</span>
                            </div>
                            <div class="line"></div>
                            <div>
                                <span>{{formatDate(team.ending)}}</span>
                                <span>Slutter</span>
                            </div>
                        </div>

                        <p>Pladser:
                            <font-awesome-icon icon="user" /> x {{team.slots}}
                        </p>
                        <p>Pris: {{dotToComma(team.price)}} DKK</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import api from '@/plugins/axio';
export default {
    name: 'teamdisplay',
    bodyClass: 'landing-page',
    props: ['teamtype'],
    data() {
        return {
            teams: null,
            weekday: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
        };
    },
    methods: {
        geteams: function () {
            
            var vue = this;

            if(this.teamtype==0){
                api().get('/teams')
                .then(function (response) {
                    if (response.data.status != "error") {
                        vue.teams = response.data;
                    }else{
                        vue.teams = []
                    }
                })
                .catch(function (error) {
                })
            }else{
                api().get('/teams/?type=' + this.teamtype)
                .then(function (response) {
                    if (response.data.status != "error") {
                        vue.teams = response.data;
                    }else{
                        vue.teams = []
                    }
                })
                .catch(function (error) {
                })
            }
        },
        dotToComma: function (data) {
            return data.toString().replace('.', ',');
        },
        timeSpan: function (from, to) {
            var from = new Date(from);
            from.setTime( from.getTime() + from.getTimezoneOffset()*60*1000 );
            var to = new Date(to);
            to.setTime( to.getTime() + to.getTimezoneOffset()*60*1000 );
            return "Hver " + this.weekday[from.getDay()] + " " + ("0" + from.getHours()).slice(-2) + ":" + ("0" + from.getMinutes()).slice(-2) + " - " + ("0" + to.getHours()).slice(-2) + ":" + ("0" + to.getMinutes()).slice(-2);
        },
        formatDate: function (raw) {
            var dag = ("0" + new Date(raw).getDate()).slice(-2);
            var måned = ("0" + (new Date(raw).getMonth() + 1)).slice(-2);
            var år = new Date(raw).getFullYear().toString().slice(-2);
            return dag + "." + måned + "." + år;
        }
    },
    mounted() {
        if (!this.teams) {
            this.geteams();
        }
    },
    created() {
        if (!this.teams) {
            this.geteams();
        }
    },
};
</script>

<style>
.teams-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team {
    flex-grow: 2;
    margin: 10px;
    max-width: 300px;
    width: 90%;
    overflow: hidden;
}

.team .top {
    background-color: #f96332;
    color: white;
    text-align: center;
}

.team .top h2 {
    margin: 0;
    word-break: break-all;
}

.team .card-body {
    display: flex;
    flex-direction: column;
}

.team p {
    margin: 7px 0;
}

.team p:first-child {
    margin-top: 0;
}

.team p:last-child {
    margin-bottom: 0;
}

.team .timeline {
    display: flex;
    align-items: center;
    margin: px 0;
}

.team .timeline div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team .timeline div span:last-child {
    font-size: 0.7rem;
    opacity: 0.8;
}

.seethis {
    font-size: 1.1rem;
    font-weight: bold;
}

.team .timeline .line {
    flex-grow: 2;
    width: 0;
    height: 2px;
    margin: 0 5px;
    background-color: #f96332;
}
</style>
