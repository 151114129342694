<template>
<div>
    <div class="page-header page-header-large">
        <parallax class="page-header-image" style="background-image: url('img/031_HH_LANDSC_7381.JPG')">
        </parallax>
        <div class="content-center">
            <div class="container">
                <h1 class="title">Esport med god tone</h1>
                <div class="text-center">
                    <!-- <a href="https://www.facebook.com/hhesport" target="_blank" class="btn btn-primary btn-round btn-icon">
              <i class="fab fa-facebook-square"></i>
            </a>

            <a href="https://discord.hhesport.dk" target="_blank" class="btn btn-primary btn-round btn-icon">
              <i class="fab fa-discord"></i>
            </a>-->
                </div>
            </div>
        </div>
    </div>
    <div class="section section-about-us">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Hvad er ESPORT?</h2>
                    <h5 class="description">
                        Esport står for 'Electronic Sport', som er en betegnelse for konkurrence i computerspil. Her er man ude i et professionelt konkurrenceplan hvor man oftest mødes fysisk i den virkelige verden og ikke bare bag en skærm. Oftest sker det til LAN Events, hvor der afholdes turneringer med gaver eller pengepræmier. 
                    </h5>
                </div>
            </div>
            <div class="separator separator-primary"></div>
            <div class="section-story-overview">
                <div class="row">
                    <div class="col-md-6">
                        <div class="image-container image-left" style="background-image: url('img/20190113_023031.jpg')">
                            <!-- First image on the left side -->
                            <p class="blockquote blockquote-primary">
                                "Esport kan styrke spillerens evner til at kommunikere, arbejde sammen og indgå i et holdfællesskab."
                                <br />
                                <small>-DGI</small>
                            </p>
                        </div>
                        <!-- Second image on the left side of the article -->
                        <div class="image-container" style="background-image: url('img/032_HH_LANDSC_7384.JPG')"></div>
                    </div>
                    <div class="col-md-5">
                        <!-- First image on the right side, above the article -->
                        <div class="image-container image-right" style="background-image: url('img/photo-1542751371-adc38448a05e.jpg')"></div>
                        <h3>

                        </h3>
                        <p>
                            I dag er der meget fokus på computeren som en underholdningsplatform, hvor man gennem spil og leg får indlæring med, som f.eks. i skolen, hvor man benytter spil som Minecraft. Spil som f.eks. Counter Strike er ved at vinde indpas på linje med andre sportsgrene, og udøves også på professionelt plan. For mange unge er det en drøm at blive professionel esportsudøver, ligesom andres drøm er at blive professionel fodboldspiller. 
                        </p>
                        <p>
                            Vi ønsker at tilbyde et sted, hvor unge kan have et socialt samvær omkring esport, samt at hjælpe dem i gang og invitere dem ind i et åbent fællesskab med ligesindede. Der vil blive tilbudt undervisning fra frivillige med ekspertise indenfor esport, hvor sund krop og sind er i fokus. 
                        </p>
                        <p>
                            Vi er en flok frivillige mellem 18 og 40 år, med en solid baggrund indenfor området, både privat, men også erhvervsmæssigt. Vores erfaring er bred, og vi vil være i stand til at besvare de fleste spørgsmål - både angående esport, men også software og hardware generelt. De fleste af os arbejder til dagligt blandt andet med softwareudvikling og serverhosting, hvor vi i fritiden har stor interesse indenfor esportsverdenen.
                            <br><br>
                            Vi står blandt andet for at afvikle <a href="https://www.hhlan.dk/" target="_blank">HH LAN</a> (et esportsevent, som afholdes 2 gange årligt i Helle Hallen, med tilhørende konkurrencer og showcases af hardware).
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Here is our team</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/avatar.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Romina Hadid</h4>
                <p class="category text-primary">Model</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/ryan.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Ryan Tompson</h4>
                <p class="category text-primary">Designer</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/eva.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Eva Jenner</h4>
                <p class="category text-primary">Fashion</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-google-plus"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-youtube"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
    <div class="text-center">
        <div class="container">
            <h2 class="title">Alle hold</h2>
            <teamdisplay teamtype="0" />
        </div>
    </div>
    <signupdisplay />
    <!--<NoticeForm />-->
</div>
</template>

<script>
import {
    Button,
    FormGroupInput
} from '@/components';
import NoticeForm from '@/pages/components/NoticeForm';
import TeamDisplay from '@/pages/components/TeamDisplay';
import SignupDisplay from '@/pages/components/Signup';

export default {
    name: 'index',
    bodyClass: 'landing-page',
    components: {
        NoticeForm: NoticeForm,
        teamdisplay: TeamDisplay,
        signupdisplay: SignupDisplay,
        [Button.name]: Button,
        [FormGroupInput.name]: FormGroupInput
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style></style>
