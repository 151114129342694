<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('../img/omos.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Om foreningen</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Hvad er HH Esport?</h2>
            <p>
                Da der har været stor forespørgsel til esport lokaler fra de lokale foreninger, har Helle Idrætssamvirke samlet en flok IT-entusiaster, der kan hjælpe med at få dette op at stå. Vi startede derfor en forening under Helle Idrætssamvirke: HH Esport, som skal have til formål at skabe interesse og udbrede viden omkring Esport. 
<br><br>

                Vi er en flok frivillige mellem 18 og 55 år, med en solid baggrund indenfor området, både privat, men også erhvervsmæssigt. Vores erfaring er bred, og vi vil være i stand til at besvare de fleste spørgsmål - både angående esport, men også software og hardware generelt. De fleste af os arbejder til dagligt blandt andet med softwareudvikling og serverhosting, hvor vi i fritiden har stor interesse indenfor esports verdenen.
                <br><br>
                Vi står blandt andet for at afvikle <a href="https://www.hhlan.dk/" target="_blank">HH LAN</a> (et esportsevent som afholdes 2 gange årligt i Helle Hallen, med tilhørende konkurrencer og showcases af hardware).
            </p>
          </div>
        </div>

      </div>
    </div>
    <!--
    <div class="section section-team text-center" style="padding-top:0;">
      <div class="container">
        <h2 class="title">Frivillige bag HH Esport</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/avatar.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Romina Hadid</h4>
                <p class="category text-primary">Model</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/ryan.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Ryan Tompson</h4>
                <p class="category text-primary">Designer</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/eva.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Eva Jenner</h4>
                <p class="category text-primary">Fashion</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-google-plus"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-youtube"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->

    <!--
    <div class="section section-contact-us text-center" style="padding-top:0;">
      <div class="container">
        <h3 class="title">Kunne du tænke dig at blive frivillig? </h3>
        <h4 class="description">Hiv fat i vores formand og hør mere omkring det.</h4>
      </div>
    </div>
    -->
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'landing',
  bodyClass: 'landing-page',
  data() {
    return {
        frivillige: [
        {
            name: 'Jonas Hansen',
            image: '', 
            rank: 'formand',
            email: 'jh@infrequent.group',
            text: 'You can write here details about one of your team members. You can give more details about what they do. Feel free to add some links for people to be able to follow them outside the site.'
        },
        
        ]
    }
  }
};
</script>
<style></style>
