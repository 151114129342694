<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('../img/vedtaegter.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Foreningens vedtægter</h1>
          <div class="text-center">
            Vedtaget på foreningens stiftende generalforsamling den 13/02-2019
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h4>§ 1. Navn og hjemsted</h4>
            <p>
                Stk. 1. Foreningens navn er HHesport.	<br>
                Stk. 2. Foreningens hjemsted er Varde kommune.<br>
            </p>
            <h4 >
                § 2. Formål<br>
            </h4>
            <p>
                Stk. 1. Foreningens formål er:<br>
                At skabe et miljø hvor unge kan arbejde med deres interesse for elektronik, programmering, 3D design og ESport.<br>
                At hjælpe unge til et sundt kritisk sind og en sund krop.<br>
                At lære unge at arbejde sammen og være en del af et velfungerende hold.<br>
                At hjælpe unge med interesse for gaming til at fokusere deres træning inden for strategi og samarbejde.<br>

            </p>
            <h4 >
                § 3. Medlemskreds
            </h4>
            <p>
                Stk. 1. Som medlemmer optages enhver, som har lyst og vilje til at arbejde for foreningens formål.<br>
                Stk. 2. Indmeldelse sker på foreningens indmeldelsesblanket ved henvendelse til foreningens formand eller kasserer. Medlemskabet er først gyldigt, når medlemmet har betalt kontingent.<br>
                Stk. 3. Udmeldelse af foreningen sker automatisk i forbindelse med ophør af kontingent perioden.<br>
                Stk. 4. Medlemmerne betaler et af generalforsamlingen fastsat kontingent. Kontingentet opkræves forud for hvert kontingent periode.<br>

            </p>
            <h4>
                § 4. Generalforsamlingen
            </h4>
            <p>
                Stk. 1. Generalforsamlingen er foreningens højeste myndighed.<br>
                Stk. 2. Ordinær generalforsamling afholdes en gang årligt inden udgangen af Marts måned og indkaldes med mindst 2 ugers varsel med angivelse af dagsorden ved brev/email til medlemmerne/ved annoncering i den lokale dagspresse eller sociale medier.<br>
                Stk. 3. Møde- og stemmeberettigede på generalforsamlingen er alle medlemmer, der senest ugedagen forinden har betalt forfaldent kontingent. Der kan ikke stemmes ved fuldmagt. <br>
                Stk. 4. Dagsordenen for den ordinære generalforsamling skal mindst indeholde følgende punkter:<br>
                1. Valg af stemmetællere<br>
                2. Valg af dirigent<br>
                3. Formandens beretning<br>
                4. Regnskabsaflæggelse<br>
                5. Behandling af indkomne forslag<br>
                6. Fastsættelse af kontingent<br>
                7. Godkendelse af budget<br>
                8. Valg af formand<br>
                9. Valg af 4 bestyrelsesmedlemmer<br>
                10. Valg af 1 revisorer<br>
                11. Eventuelt<br>

                Stk. 5. Forslag, der ønskes behandlet på generalforsamlingen, skal være bestyrelsen i hænde senest 8 dage før generalforsamlingen. Forslag om vedtægtsændringer, der ønskes behandlet, skal være bestyrelsen i hænde senest en måneder forud for udgangen af den måned, hvor den ordinære generalforsamling skal afholdes.<br>
                Stk. 6. Generalforsamlingen træffer sine beslutninger med simpelt flertal (en over halvdelen af de gyldigt afgivne stemmer) og ved håndsoprækning. Skriftlig afstemning anvendes dog, når det begæres af blot en mødedeltager. Ved personvalg, hvor der er foreslået flere end det antal, der skal vælges, foretages altid skriftlig afstemning. Ved sådanne personvalg anvendes reglen om simpelt flertal. Ved tilfælde af 50/50 afstemning, vil tidligere formands stemme vægte 2 stemmer.<br>

            </p>
            <h4>
                § 5. Ekstraordinær generalforsamling
            </h4>
            <p>
                Stk. 1. Ekstraordinær generalforsamling kan afholdes, når bestyrelsen finder det nødvendigt, og skal afholdes, når mindst 1/3 af medlemmerne fremsætter skriftligt begrundet anmodning om det over for formanden. I sådanne tilfælde skal generalforsamlingen afholdes senest fire uger efter, at anmodningen er kommet til formandens kundskab.<br>
                Stk. 2. Indkaldelsesfristen for en ekstraordinær generalforsamling er to uger.<br>
            
            </p>
            <h4>
                § 6. Foreningens daglige ledelse
            </h4>
            <p>
                Stk. 1. Foreningens daglige ledelse udgøres af bestyrelsen, der foruden formanden består af 4 medlemmer. Bestyrelsen vælges af generalforsamlingen for en toårig periode, således at der hvert år vælges 2 medlemmer. Formanden vælges for 1 år af gangen.<br>
                Stk. 2. Bestyrelsen leder foreningen i overensstemmelse med nærværende vedtægter og generalforsamlingens beslutninger.<br>
                Stk. 3. Senest 14 dage efter nyvalg til bestyrelsen afholdes konstituerende møde, hvor bestyrelsen af og blandt sine medlemmer vælger næstformand, kasserer og sekretær. Alle tillidsposter gælder frem til næste generalforsamling.<br>
                Stk. 4. Bestyrelsen fastsætter i øvrigt selv sin forretningsorden. Den kan nedsætte underudvalg og arbejdsgrupper til varetagelse af afgrænsede opgaver.<br>
                Stk. 5. Formanden - og i hans fravær næstformanden - indkalder og leder bestyrelsens møder. Indkaldelse sker skriftligt med angivelse af dagsorden, når formanden skønner det nødvendigt, eller mindst tre medlemmer af bestyrelsen fremsætter ønsker om det overfor formanden. I sådanne tilfælde afholdes mødet senest to uger efter anmodningen er kommet til formandens kendskab.<br>
                Stk. 6. Bestyrelsen er beslutningsdygtig, når mindst 3 bestyrelsesmedlemmer er tilstede.<br>

            </p>
            <h4 >
                § 7. Økonomi, regnskab og revision
            </h4>
            <p>
                Stk. 1. Foreningens regnskabsår følger kalenderåret.<br>
                Stk. 2. Bestyrelsen er ansvarlig overfor generalforsamlingen for budget samt regnskab. <br>
                Stk. 3. Foreningens regnskab føres af kassereren, der tillige fører foreningens medlemsregister.<br>
                Stk. 4. Regnskabet revideres af de på generalforsamlingen valgte revisor.<br>

            </p>
            <h4>
                § 8. Tegningsregler og hæftelse
            </h4>
            <p>
                Stk. 1. Foreningen tegnes udadtil ved underskrift af formanden og mindst et yderligere bestyrelsesmedlem i forening. Ved optagelse af lån og ved salg/pantsætning af fast ejendom tegnes foreningen af den samlede bestyrelse. <br>
                Stk. 2. Der påhviler ikke foreningens medlemmer nogen personlig hæftelse for de forpligtelser, der påhviler foreningen.<br>

            </p>
            <h4>
                § 9. Vedtægtsændringer
            </h4>
            <p>
                Stk. 1. Disse vedtægter kan kun ændres med 2/3 flertal på en generalforsamling, hvor ændringsforslaget fremgår af dagsordenen. <br>
                Stk. 2. Vedtægtsændringer træder i kraft med virkning fra den generalforsamling, de vedtages på.<br>

            </p>
            <h4 >
                § 10. Opløsning
            </h4>
            <p>
                Stk. 1. Opløsning af foreningen kan kun finde sted med 2/3 flertal på to hinanden følgende generalforsamlinger, hvoraf den ene skal være ordinær.<br>
                Stk. 2. Den opløsende generalforsamling afgør hvem aktiverne skal overdrages til. Jævnfør §10 stk. 3<br>
                Stk. 3. Aktiver må kun henfalde demokratiske foreninger.<br>

            </p>
            <h4 >
                § 11. Datering
            </h4>
            <p>
                Stk. 1. Således vedtaget på foreningens stiftende generalforsamling den 13/02-2019.<br>
            </p>
          </div>
        </div>
        <div class="separator separator-primary"></div>

      </div>
    </div>
    
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'vedtaegter',
  bodyClass: 'landing-page',
  data() {
    return {
    };
  }
};
</script>
<style></style>
