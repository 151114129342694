<template>
<div class="section section-howtosignup text-center">
    <div class="container">
        <h2 class="title">Hvordan kommer du igang?</h2>
        <p>
            Man kan tilmelde sig ved at kontakte os via <a target="_blank" href="https://m.me/hhesport">facebook, messenger</a> eller via <a href="tel:+4546975068">telefon +45 46 97 50 68</a>.
        </p>
        <p>
            Holdtiderne forløber udelukkende på folkeskolens skoledage, så der er ingen holdtræning i ferier og helligdage, medmindre andet er aftalt. 
        </p>
    </div>
</div>
</template>

<script>
import api from '@/plugins/axio';
export default {
    name: 'teamdisplay',
    bodyClass: 'landing-page',
    data() {
        return {};
    },
    methods: {},
    mounted() {},
    created() {},
};
</script>

<style>
.section-howtosignup {
    padding-top: 0px !important;
}
</style>
