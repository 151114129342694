<template>
<div class="section section-contact-us text-center" style="padding-top:0;">
    <div class="container">
        <h2 class="title">Kunne du tænke dig at blive medlem?</h2>
        <p>Skriv dig op til at blive kontaktet når vi åbner</p>
        <div class="row" v-if="!formsent">
            <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
                <fg-input class="input-lg" placeholder="Fulde navn" v-model="form.firstName" addon-left-icon="now-ui-icons users_circle-08" required>
                </fg-input>
                <fg-input type="email" class="input-lg" placeholder="Email" v-model="form.email" addon-left-icon="now-ui-icons ui-1_email-85" required>
                </fg-input>
                <fg-input type="number" class="input-lg" placeholder="Telefon" v-model="form.tlf" addon-left-icon="now-ui-icons tech_mobile" required>
                </fg-input>
                <div class="send-button">
                    <n-button type="primary" v-on:click="signup" round block size="lg">Skriv mig op!</n-button>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-lg-6 text-center ml-auto mr-auto col-md-8" style="color:green;">
                <h4>Vi har skrevet dig op på kontakt listen!</h4>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    Button,
    FormGroupInput
} from '@/components';
import api from '@/plugins/axio';
export default {
    name: 'noticeform',
    bodyClass: 'landing-page',
    components: {
        [Button.name]: Button,
        [FormGroupInput.name]: FormGroupInput
    },
    data() {
        return {
            formsent: false,
            form: {
                firstName: '',
                email: '',
                tlf: ''
            }
        };
    },
    methods: {
        signup: function () {
            var vue = this;
            if (this.form.firstName && this.form.email && this.form.tlf) {

                api().post('/web/getnotice', {
                        firstName: this.form.firstName,
                        email: this.form.email,
                        tlf: this.form.tlf
                    })
                    .then(function (response) {
                        console.log(response);
                        if (response.data.status != "error") {
                            vue.formsent = true;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                console.log("form submit");
            }
        }
    },
};
</script>

<style>
@media (max-width: 450px) {

    .landing-page .section-contact-us .input-group,
    .landing-page .section-contact-us .send-button,
    .landing-page .section-contact-us .textarea-container {
        padding: 0;
    }
}
</style>
