import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTshirt, faDumbbell, faKey, faCampground, faPiggyBank, faMoneyBillWave, faGamepad, faUser, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTshirt, faDumbbell, faKey, faCampground, faPiggyBank, faMoneyBillWave, faGamepad, faUser, faStar)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false;

Vue.use(NowUiKit);
//and then use it in main.js

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');