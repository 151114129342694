<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <router-link to="/foreningen/omos" class="btn-hover">
              Omkring os
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }} HHEsport - Hostet af
        <a href="https:///www.infrequent.dk" target="_blank" rel="noopener"
          >Infrequent.dk</a
        >.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.footer .btn-hover{
  transition: all 300ms ease;
}

.footer .btn-hover:hover{
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.1875rem;
}
</style>
