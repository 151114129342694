import { render, staticRenderFns } from "./Csgo.vue?vue&type=template&id=3c7bdd61&"
import script from "./Csgo.vue?vue&type=script&lang=js&"
export * from "./Csgo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports