<template>
<div>
    <div class="page-header page-header-small">
        <parallax class="page-header-image" style="background-image: url('../img/pc.jpg')">
        </parallax>
        <div class="content-center">
            <div class="container">
                <h1 class="title">Medlems kontingent</h1>
                <div class="text-center">
                    Hvad koster det at være en del af HH ESPORT?
                </div>
            </div>
        </div>
    </div>

    <div class="section section-team text-center">
        <div class="container">
            <h2 class="title">Medlemsfordele</h2>
            <div class="text-center ">Du er automatisk medlem af foreningen når du er medlem af et hold</div>
            <div class="advanteges">
                <div>
                    <font-awesome-icon icon="dumbbell" />
                    <p>Holdtræning med fælleskab</p>
                </div>
                <div>
                    <font-awesome-icon icon="tshirt" />
                    <p>Tilladelse til at bære vores hold trøjer</p>
                </div>
                <div>
                    <font-awesome-icon icon="key" />
                    <p>Mulighed for at booke lokalet</p>
                </div>
                <div>
                    <font-awesome-icon icon="campground" />
                    <p>Blive inviteret med til Bootcamps</p>
                </div>
                <div>
                    <font-awesome-icon icon="piggy-bank" />
                    <p>Tilskud til deltagelse ved LAN Events</p>
                </div>
                <div>
                    <font-awesome-icon icon="star" />
                    <p>Unikt rank på foreningens servere</p>
                </div>
            </div>
        </div>
    </div>
    <!--
    <div class="section pricing" style="background-image: url('../img/kontigent-sub.jpg')">
        <div class="card">
            <div class="card-body">
                <h2>750 DKK</h2>
                <p>/halvårlig</p>
            </div>
            <font-awesome-icon class="bg-ilustration" icon="money-bill-wave" />
        </div>
    </div>-->

    <!--<NoticeForm></NoticeForm>-->
</div>
</template>

<script>
import NoticeForm from '@/pages/components/NoticeForm';
export default {
    name: 'landing',
    bodyClass: 'landing-page',
    components: {
        NoticeForm: NoticeForm,
    },
    data() {
        return {
            form: {
                firstName: '',
                email: '',
                tlf: ''
            }
        };
    }
};
</script>

<style>
.advanteges {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.advanteges>div {
    font-weight: bold;
    flex-grow: 2;
    flex-shrink: 2;
    word-wrap: break-word;
    margin: 15px;
    padding: 15px;
    border-radius: .1875rem;
    max-width: 320px;
    width: 90%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: box-shadow 300ms cubic-bezier(.25, .8, .25, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    animation: fadeIn 1.2s cubic-bezier(.25, .8, .25, 1);
}

.advanteges>div:nth-child(1) {
    animation-delay: 0.5s;
}

.advanteges>div:nth-child(2) {
    animation-delay: 0.7s;
}

.advanteges>div:nth-child(3) {
    animation-delay: 0.9s;
}

.advanteges>div:nth-child(4) {
    animation-delay: 1.1s;
}

.advanteges>div:nth-child(5) {
    animation-delay: 1.3s;
}

.advanteges>div:nth-child(6) {
    animation-delay: 1.5s;
}

.advanteges>div:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.advanteges>div svg {
    margin-bottom: 15px;
    font-size: 2.5rem;
    color: #f96332;
}

.advanteges>div p {
    margin: 0;
    font-size: 1.5rem;
    line-height: 2rem;
}

@keyframes fadeIn {
    50% {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    100%,
    0% {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
}

.pricing {
    background-size: cover !important;
    background-position: top center !important;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
}

.pricing .card {
    max-width: 320px;
    width: 90%;
    text-align: center;
    background-color: #f96332;
    color: white;
    overflow: hidden;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: box-shadow 300ms cubic-bezier(.25, .8, .25, 1);
    cursor: default;
    user-select: none;
    margin-bottom: 0;
}

.pricing .card:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.pricing .bg-ilustration {
    position: absolute;
    color: white;
    opacity: 0.3;
    font-size: 5rem;
    bottom: -5px;
    right: -7px;
    transform: rotate(-30deg);
}

.pricing h2 {
    margin: 0;
}

.pricing p {
    margin: 0;
}
</style>
