<template>
<div id="app">
    <router-view name="header" />
    <div class="wrapper">
        <!-- Load Facebook SDK for JavaScript -->
        <div id="fb-root"></div>
        <!-- Your Chat Plugin code -->
        <div class="fb-customerchat" attribution=setup_tool page_id="110618563662616" theme_color="#ff7e29"></div>

        <router-view />
    </div>
    <router-view name="footer" />
</div>
</template>

<script>
export default {};
</script>
<script>
window.fbAsyncInit = function () {
    FB.init({
        xfbml: true,
        version: 'v9.0'
    });
};

(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/da_DK/sdk/xfbml.customerchat.js';
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
</script>

<style>
#app {
    display: flex;
    flex-direction: column;
}

#app>.wrapper {
    flex-grow: 2;
}

#app,
body,
html {
    height: 100%;
}

a:focus {
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>
