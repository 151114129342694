<template>
<div>
    <div class="page-header page-header-small">
        <parallax class="page-header-image" style="background-image: url('../img/csgo.jpg')">
        </parallax>
        <div class="content-center">
            <div class="container">
                <h1 class="title">Esports hold med CS:GO</h1>
                <div class="text-center">

                </div>
            </div>
        </div>
    </div>

    <div class="section section-game-info text-center" style="padding-bottom:0;">
        <div class="container">
            <h2 class="title">Hvad er CS:GO?</h2>
            <p class="description">
                CS:GO eller Counter-Strike Global Offensive er et af de mest populære skydespil i verden. En kamp bliver spillet imellem to hold, hvor målet er at enten skyde alle sine modstandere eller at fuldføre missionen. De to hold har nemlig ikke ens missioner; det ene hold agerer som anti-terrorister og det andet som terrorister. Terroristernes mål er at sprænge en bombe på et af de to designerede steder på kortet, imens anti-terroristernes mål er at stoppe bomben i at sprænge. 
            </p>
        </div>
    </div>

    <teamdisplay teamtype="1"></teamdisplay>
    <signupdisplay />

</div>
</template>

<script>
import TeamDisplay from '@/pages/components/TeamDisplay';
import SignupDisplay from '@/pages/components/Signup';
export default {
    name: 'csgo',
    bodyClass: 'csgo-page',
    components: {
        teamdisplay: TeamDisplay,
        signupdisplay: SignupDisplay
    },
    data() {
        return {};
    }
};
</script>

<style></style>
