<template>
<navbar position="fixed" type="primary" :transparent="transparent" :color-on-scroll="colorOnScroll" menu-classes="ml-auto">
    <template slot-scope="{ toggle, isToggled }">
        <router-link v-popover:popover1 class="navbar-brand" to="/">
            <svg width="163" height="50" xmlns="http://www.w3.org/2000/svg">
                <g fill="#FFF" fill-rule="evenodd">
                    <path d="M5.542 17.072c-1.78 6.473-9.79 8.334-2.59 21.038-2.527-13.533 1.539-11.076 2.59-21.038" />
                    <path d="M4.895 14.401s1.073 1.78 2.427 2.105c-1.78-2.347-1.78-3.399-1.78-3.399l.97.808c-1.294-6.149 5.423-5.987 5.423-5.987-7.353-2.206-7.946 2.084-10.52 3.156 1.498-.025 3.075-.89 3.075-.89-1.719 2.67-2.67 8.981-4.49 13.594 2.771-2.34 4.895-9.387 4.895-9.387" />
                    <path d="M14.848 8.656c.566-2.059-2.508-9.548-10.681-2.59 5.373-.95 7.768-2.022 10.68 2.59" />
                    <path d="M16.587 9.223c1.49-8.598 4.977-6.636 7-8.981-.971.809-5.099-.405-7 4.127-2.65-3.037-2.89-1.443-5.479-4.369.89 3.156 4.711 3.169 5.48 9.223" />
                    <path d="M18.084 8.965c4.34-6.357 5.644-2.58 11.121-1.487-2.305-2.109-3.842-4.049-6.373-4.08-1.85-.025-4.35.546-4.748 5.567" />
                    <path d="M30.99 11.084c-1.922-1.113-4.025-5.32-10.155-3.155 7.627.323 5.826 5.784 5.826 5.784s.405-.1.742-.728c-.337 1.901-1.834 3.48-1.834 3.48 1.983-.688 2.387-1.901 2.387-1.901s2.023 6.858 5.138 9.223c-2.266-3.66-1.921-9.223-4.53-13.35 1.334.87 2.426.647 2.426.647" />
                    <path d="M30.828 38.11c4.946-11.975.668-10.833-3.489-21.038 1.032 8.385 4.915 9.063 3.49 21.038M8.314 23.95c-.183 1.618-.971 2.65-1.922 7.16-.708-2.144 1.578-7.737 1.193-8.354 0 0-.88 1.557-2.548 5.887.374-3.692 1.739-5.564 1.517-6.979-7.162 10.681-.586 14.686 2.549 22.514.02-.99 0-2.063 0-2.063s2.038 1.804 4.794 5.644c-.91-4.996-6.008-8.01-6.292-15.191-.16-3.663.87-3.298.709-8.618" />
                    <path d="M17.113 44.967c-.82-8.162-4.52-7.89-5.887-11.227-1.366-3.338.709-3.186-2.063-7.606 1.306 5.817-3.428 5.18 4.734 18.014-.455-3.702-1.062-5.25-1.062-5.25s2.305 2.155 4.278 6.07M18.175 43.996c1.942-3.975 4.946-6.28 5.553-9.284.365-1.76-.364-4.673.03-8.578-.862 1.093-1.923 3.932-1.82 5.755.122 2.156-.212 6.221-5.856 5.826 0 0 .788 1.063 3.67.88 0 0-1.637 2.154-1.577 5.401" />
                    <path d="M27.794 28.764c-1.456-3.156-.622-2.124-2.306-6.008-.424.788.97 4.43 1.213 8.496-1.456-3.863-1.243-5.325-2.063-6.918.137 3.975.592 4.278.73 7.707.53 6.63-7.404 10.439-7.283 17.72 1.29-4.794 6.432-8.678 6.432-8.678s-.546.638-.684 3.581c3.132-8.375 9.787-10.759 2.505-23 .363 2.67 1.213 3.743 1.456 7.1M14.949 32.79s-.638-.678-.768-1.366c-.173.84 0 1.365 0 1.365.346.102.768 0 .768 0M19.075 31.515c-.101.638-.779 1.274-.779 1.274s.436.117.77 0c0 0 .222-.435.01-1.274M12.177 26.013s0 1.234 1.012 2.184c-.02-1.618.242-2.75.242-2.75-.627.122-1.254.566-1.254.566M21.22 26.337s-.63-.516-1.263-.607c0 0 .19 1.011 0 2.387 1.06-.667 1.263-1.78 1.263-1.78M16.21 35.902c.417-.118.66-.078.904-.007.242.07 2.616 1.494 3.224-2.473-1.245-.076-3.468.25-3.468.25s-.462.052-.788-.016c-.327-.065-2.026-.264-3.163-.234.31 3.94 2.875 2.6 3.291 2.48" />
                    <path d="M21.867 16.283l2.994 5.28s.385-1.214 1.052-1.78c.222-.607-.121-2.55-.121-2.55s-.79.526-1.437.546c2.316-6.642-.347-8.892-3.135-8.806-1.828.057-.882 1.14-4.633 1.484-2.994.061-2.953-1.521-4.648-1.503-2.715.011-5.793 2.535-2.98 8.745-.908.141-1.677-.303-1.677-.303s-.323 2.103 0 2.508c0 0 .85.121.91 1.76 1.073-2.205 3.136-5.361 3.136-5.361s.04-1.336-2.713-2.933c2.309-.506 3.785 1.355 3.785 1.355s1.17-.716.405-1.618c2.77 1.416-4.001 6.165-3.54 9.993.16 1.336.202 1.902 1.619 3.116 4.854-3.318 4.328-1.74 5.016-2.65.485-.466.182-1.315.182-1.315-.588-1.335-3.277-1.498-3.358-2.994 4.389 1.456 7.614 0 7.614 0 .66.89-2.982 2.103-3.225 2.994 0 0-.242.647 0 1.254.97.121 5.24 2.71 5.24 2.71s1.233-.324 1.598-3.196c.12-3.722-6.5-8.982-3.613-9.892-.738 1.153.497 1.638.497 1.638s.324-1.78 3.743-1.374c-1.882.85-2.71 2.892-2.71 2.892" />
                    <path d="M51.247 32.353v-6.918h-4.965v6.918h-4.07V14.47h4.07v6.894h4.965V14.47h4.047v17.882h-4.047zm15.153 0v-6.918h-4.965v6.918h-4.07V14.47h4.07v6.894H66.4V14.47h4.047v17.882H66.4zm13.224 0V14.47H90.8v4.423h-7.082v2.541h6.117v3.789h-6.117v2.635h7.13v4.494H79.623zm12.8 0v-4.494h8.023c.47 0 .706-.235.706-.706v-1.177c0-.47-.235-.705-.706-.705h-4.753c-2.196 0-3.294-1.044-3.294-3.13v-4.259c0-2.274 1.059-3.411 3.176-3.411h9.224v4.494h-7.624c-.47 0-.705.235-.705.706v1.153c0 .47.235.705.705.705h4.895c2.086 0 3.129 1.051 3.129 3.153v4.189c0 2.321-1.137 3.482-3.412 3.482h-9.364zm23.623-12.682c0-.471-.235-.706-.706-.706h-4.094v2.659h4.094c.47 0 .706-.236.706-.706V19.67zm-8.87 12.682V14.47h9.694c2.149 0 3.223 1.113 3.223 3.34v4.707c0 2.055-1.074 3.082-3.223 3.082h-5.624v6.753h-4.07zM130.47 19.67c0-.471-.236-.706-.706-.706h-3.389c-.47 0-.705.235-.705.706v7.482c0 .47.235.706.705.706h3.389c.47 0 .706-.235.706-.706V19.67zm-5.647 12.682c-2.15 0-3.224-1.043-3.224-3.13V17.742c0-2.18 1.074-3.27 3.224-3.27h6.47c2.15 0 3.224 1.09 3.224 3.27v11.483c0 2.086-1.075 3.129-3.224 3.129h-6.47zm20.54-12.8c0-.47-.235-.706-.705-.706h-4.094v2.659h4.094c.47 0 .706-.235.706-.706v-1.247zm.118 12.8a3.308 3.308 0 0 1-.117-.941V26c0-.47-.236-.706-.706-.706h-4.094v7.059h-4.07V14.47h9.623c2.164 0 3.247 1.113 3.247 3.34v2.824c0 1.616-.66 2.541-1.977 2.777.706.157 1.22.447 1.541.87.322.424.483.989.483 1.694v5.295c0 .313.012.54.035.682.024.141.051.274.082.4h-4.047zm9.365 0V18.965h-4.094V14.47h12.235v4.494h-4.094v13.388h-4.047z" fill-rule="nonzero" />
                </g>
            </svg>
        </router-link>
    </template>
    <template slot="navbar-menu">
        <li class="nav-item">
            <a class="nav-link" rel="tooltip" title="Gå til forside" data-placement="bottom" href="/">
                <i class="now-ui-icons objects_spaceship"></i>
                <p>Forside</p>
            </a>
        </li>
        <drop-down tag="li" title="Hold" icon="now-ui-icons design_app" class="nav-item">
            <nav-link to="/hold/csgo">
                <i class="now-ui-icons tech_controller-modern"></i> CS:GO
            </nav-link>
            <nav-link to="/hold/lol">
                <i class="now-ui-icons tech_controller-modern"></i> LOL
            </nav-link>
            <nav-link to="/hold/pubg">
                <i class="now-ui-icons tech_controller-modern"></i> PUBG
            </nav-link>
        </drop-down>
        <drop-down tag="li" title="Foreningen" icon="now-ui-icons travel_info" class="nav-item">
            <nav-link to="/foreningen/omos">
                <i class="now-ui-icons users_single-02"></i> Om os
            </nav-link>
            <nav-link to="/foreningen/kontingent">
                <i class="now-ui-icons business_money-coins"></i> Kontingent
            </nav-link>
            <nav-link to="/foreningen/vedtaegter">
                <i class="now-ui-icons education_paper"></i> Vedtægter
            </nav-link>
            <nav-link to="/foreningen/servere">
                <i class="now-ui-icons tech_laptop"></i> Servere
            </nav-link>
        </drop-down>
        <li class="nav-item">
            <a class="nav-link" rel="tooltip" title="Join vores Discord Server" data-placement="bottom" href="https://discord.hhesport.dk" target="_blank">
                <i class="fab fa-discord only-icon"></i>
                <p class="d-lg-none d-xl-none">Discord</p>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" rel="tooltip" title="Like os på Facebook" data-placement="bottom" href="https://www.facebook.com/hhesport" target="_blank">
                <i class="fab fa-facebook-square only-icon"></i>
                <p class="d-lg-none d-xl-none">Facebook</p>
            </a>
        </li>
<!--
        <li class="nav-item">
            <a class="nav-link btn btn-neutral log-in" href="https://medlem.hhesport.dk" target="_blank">
                <p>Medlemside</p>
                <i class="fas fa-sign-in-alt"></i>
            </a>
        </li>-->
    </template>
</navbar>
</template>

<script>
import {
    DropDown,
    NavbarToggleButton,
    Navbar,
    NavLink
} from '@/components';
import {
    Popover
} from 'element-ui';
export default {
    name: 'main-navbar',
    props: {
        transparent: Boolean,
        colorOnScroll: Number
    },
    components: {
        DropDown,
        Navbar,
        NavbarToggleButton,
        NavLink,
        [Popover.name]: Popover
    }
};
</script>

<style>
.bg-primary {
    background-color: #f26200 !important;
}

nav {
    user-select: none;
}

.nav-link i:first-child:not(:last-child):not(.only-icon),
.nav-link a>svg {
    margin-right: 5px;
}

.nav-link i:last-child:not(:first-child):not(.only-icon) {
    margin-left: 3px;
}

@media screen and (max-width: 991px) {

    .sidebar-collapse .navbar .dropdown.show .dropdown-menu,
    .sidebar-collapse .navbar .dropdown .dropdown-menu {
        height: max-content !important;
    }
}
</style>
