<template>
<div>
    <div class="page-header page-header-small">
        <parallax class="page-header-image" style="background-image: url('../img/pubg.jpg')">
        </parallax>
        <div class="content-center">
            <div class="container">
                <h1 class="title">Esports hold med PUBG</h1>
                <div class="text-center">

                </div>
            </div>
        </div>
    </div>

    <div class="section section-game-info text-center" style="padding-bottom:0;">
        <div class="container">
            <h2 class="title">Hvad er PUBG?</h2>
            <p class="description">
                PUBG eller PlayerUnknown’s Battlegrounds er en blanding mellem et overlevelsesspil og et skydespil. Spillet starter med at man er i et fly sammen med 99 andre spillere. Målet er så at være den sidste på banen ud af alle disse spillere. Når man er sprunget ud af flyet går man i gang med at indsamle våben, ammunition og beskyttelse for at dræbe andre spillere og forsvare sig selv. 
            </p>
        </div>
    </div>

    <teamdisplay teamtype="2"></teamdisplay>

    <signupdisplay />
</div>
</template>

<script>
import TeamDisplay from '@/pages/components/TeamDisplay';
import SignupDisplay from '@/pages/components/Signup';
export default {
    name: 'pubg',
    bodyClass: 'pubg-page',
    components: {
        teamdisplay: TeamDisplay,
        signupdisplay: SignupDisplay
    },
    data() {
        return {}
    }
};
</script>

<style></style>
