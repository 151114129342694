<template>
<div>
    <div class="page-header page-header-small">
        <parallax class="page-header-image" style="background-image: url('../img/servere.jpg')">
        </parallax>
        <div class="content-center">
            <div class="container">
                <h1 class="title">Foreningens servere</h1>
                <div class="text-center">
                    Kom og spil med os på vores forskellige servere
                </div>
            </div>
        </div>
    </div>
    <div class="section section-about-us">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto server" v-for="(server,index) in servers" :key="index">
                    <h4>{{server.name}}</h4>
                    <p>{{server.desc}}</p>
                    <a v-if="server.platform=='steam'" :href="('steam://connect/'+server.ip)">
                        <n-button type="primary" round block size="lg">{{server.ip}}</n-button>
                    </a>
                    <n-button v-else type="primary" round block size="lg">{{server.ip}}</n-button>
                </div>
            </div>
            <div class="separator separator-primary"></div>

        </div>
    </div>

</div>
</template>

<script>
import {
    Button,
    FormGroupInput
} from '@/components';
export default {
    name: 'servere',
    bodyClass: 'landing-page',
    data() {
        return {
            servers: [{
                icon: 'minecraft.jpeg',
                name: 'Minecraft server',
                platform: 'minecraft',
                desc: 'Vores fælles server med survival, bygge og pvp.',
                ip: 'hhesport.dk',
            }, {
                icon: 'csgo.jpeg',
                name: 'CS:GO Hygge',
                platform: 'steam',
                desc: 'Hyggelig server med forskellige maps.',
                ip: 'game001.hhesport.dk:25017',
            }, {
                icon: 'csgo.jpeg',
                name: 'CS:GO Træning',
                platform: 'steam',
                desc: 'CS:GO Træningsserver til hold',
                ip: 'game001.hhesport.dk:25015',
            }]
        };
    }
};
</script>

<style>
.server {
    border: 1px solid rgb(83, 83, 82);
    margin: 1rem;
    padding: 1rem;
}

.server>h4 {
    margin-top: 0;
}
</style>
