<template>
<div>
    <div class="page-header page-header-small">
        <parallax class="page-header-image" style="background-image: url('../img/lol.jpg')">
        </parallax>
        <div class="content-center">
            <div class="container">
                <h1 class="title">Esports hold med LOL</h1>
                <div class="text-center">

                </div>
            </div>
        </div>
    </div>

    <div class="section section-game-info text-center" style="padding-bottom:0;">
        <div class="container">
            <h2 class="title">Hvad er LOL?</h2>
            <p class="description">
                LOL eller League of Legends er et gratis MOBA (Multiplayer online battle arena) spil. Spillet udgøres af 10 spillere med 5 på hvert hold. I MOBA-spil udvælger hver spiller en karakter. Disse karakterer har visse evner og egenskaber som gør at de er gode til en specifik rolle i spillet. Når kampen er gået igang handler det om at samarbejdes for at vinde, dette gøres bedst ved at spille til ens karakterers fordele.
            </p>
        </div>
    </div>

    <teamdisplay teamtype="3"></teamdisplay>

    <signupdisplay />
</div>
</template>

<script>
import TeamDisplay from '@/pages/components/TeamDisplay';
import SignupDisplay from '@/pages/components/Signup';
export default {
    name: 'lol',
    bodyClass: 'lol-page',
    components: {
        teamdisplay: TeamDisplay,
        signupdisplay: SignupDisplay
    },
    data() {
        return {}
    }
};
</script>

<style></style>
