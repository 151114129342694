import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';

import Csgo from './pages/hold/Csgo.vue';
import Pubg from './pages/hold/Pubg.vue';
import lol from './pages/hold/lol.vue';

import Kontingent from './pages/foreningen/Kontingent.vue';
import Omos from './pages/foreningen/Omos.vue';
import Vedtægter from './pages/foreningen/Vedtægter.vue';
import Servere from './pages/foreningen/Servere.vue';

import Login from './pages/Login.vue';
import Profile from './pages/Profile.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    { path: '/hold/1', redirect: '/hold/csgo' },
    { path: '/hold/2', redirect: '/hold/pubg' },
    { path: '/hold/3', redirect: '/hold/lol' },
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/hold/csgo',
      name: 'csgp',
      components: { default: Csgo, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/hold/pubg',
      name: 'pubg',
      components: { default: Pubg, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/hold/lol',
      name: 'lol',
      components: { default: lol, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/foreningen/omos',
      name: 'omos',
      components: { default: Omos, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/foreningen/Kontingent',
      name: 'kontingent',
      components: { default: Kontingent, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/foreningen/vedtaegter',
      name: 'vedtaegter',
      components: { default: Vedtægter, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/foreningen/servere',
      name: 'servere',
      components: { default: Servere, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/profile',
      name: 'profile',
      components: { default: Profile, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
